<template>
    <div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="title" label="合集名称" width="180"></el-table-column>
            <el-table-column prop="detail" label="详细信息" show-overflow-tooltip min-width="180"></el-table-column>
            <el-table-column prop="img" label="图片" min-width="180">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="scope.row.img" :preview-src-list="[scope.row.img]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="上传时间" min-width="180">
                <template slot-scope="scope">
                    {{ new Date(scope.row.createdAt).toLocaleString() }}
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="合集" :visible.sync="dialogSetClampFormVisible">
            <el-form ref="formSetClamp" :model="formSetClamp" label-width="100px">
                <el-form-item label="合集名称" prop="title" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="formSetClamp.title"></el-input>
                </el-form-item>
                <el-form-item label="说明" prop="detail" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input :rows="5" type="textarea" v-model="formSetClamp.detail"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-upload class="upload-demo" drag action="/admin-api/upload" :on-success="handleSetClampUploadSuccess">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖到此处，或
                            <em>点击上传</em>
                        </div>
                        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="图片" prop="img" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="formSetClamp.img"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogSetClampFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSetClamp">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import F from './fetch';
import { confirmation } from '@/utils/decorator';

export default {
    name: 'ResourceList',
    components: {},
    data() {
        return {
            filter: {
                pageNo: 1,
                pageSize: 10,
                modeIds: [],
                musicalIds: [],
            },
            radio: '',
            musicalList: [],
            setClampList: [],
            modeList: [],
            tagList: [],

            tableData: [{}],
            total: 0,
            form: {
                tagList: [],
                setClampList: [],
                modeList: [],
            },

            formSetClamp: {
                title: '',
                detail: '',
                img: '',
            },
            dialogFormVisible: false,
            dialogSetClampFormVisible: false,
        };
    },
    async created() {
        F.fetchAllMusical().then(data => {
            this.musicalList = data;
        });
        F.fetchAllTag().then(data => {
            this.tagList = data;
        });
        F.fetchAllMode().then(data => {
            this.modeList = data;
        });
        F.fetchAllSetClamp().then(data => {
            this.setClampList = data;
        });

        this.fetchList();
    },
    mounted() {
        console.log(`this`, this);
        window.TTT = this;
    },
    methods: {
        addSetClamp() {
            this.dialogSetClampFormVisible = true;
            this.formSetClamp = {
                title: '',
                detail: '',
                img: '',
            };
        },

        saveSetClamp() {
            this.$refs.formSetClamp.validate().then(() => {
                if (this.formSetClamp.id) {
                    F.addSetClamp(this.formSetClamp).then(e => {
                        this.fetchList(this.filter);
                        this.dialogSetClampFormVisible = false;
                        this.$message.success('成功');
                    });
                } else {
                    F.updateSetClamp(this.formSetClamp).then(e => {
                        this.fetchList(this.filter);
                        this.dialogSetClampFormVisible = false;
                        this.$message.success('成功');
                    });
                }
            });
        },

        handleSetClampUploadSuccess(res, file) {
            this.formSetClamp.img = `/upload/${res.data}`;
        },

        onSearch() {
            this.filter.pageNo = 1;
            this.fetchList(this.filter);
        },

        fetchList() {
            F.fetchAllSetClamp().then(data => {
                this.tableData = data;
            });
        },
        editAmount(record, type) {
            this.editForm = {
                amount: '',
                remark: '',
                id: record.id,
                type: type,
            };
            this.visible = true;
        },

        // @confirmation('确定添加机器预算单吗？')
        openApproveUrl(record) {},

        mappingArr(arr) {
            return arr.map(item => item.id || item.value);
        },

        handleEdit(record) {
            const val = JSON.parse(JSON.stringify(record));
            this.formSetClamp = val;
            this.dialogSetClampFormVisible = true;
        },
        @confirmation('删除？')
        handleDelete(record) {
            F.delSetClamp(record.id).then(data => {
                this.fetchList();
            });
        },
    },
};
</script>

<style scoped>
.title {
    display: flex;
}
.left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
    padding: 20px;
    flex: 1;
}
.right {
    width: 300px;
    padding: 2px 20px;
    display: flex;

    align-items: flex-start;
    justify-content: flex-end;
}
</style>
